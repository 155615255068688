<template>
  <div class="receipt-note-detail">
    <breadcrumb style="margin-left: 15px; margin-bottom: -5px"></breadcrumb>
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`金额信息`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="operateReceiptNote"
            :text="$t('审核')"
            :params="{
              action: 'check',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            v-if="isDetail && ['WAIT_CHECK', 'WAIT_PAY'].includes(formData.status)"
            api="operateReceiptNote"
            :text="$t('作废')"
            type="danger"
            :params="{
              action: 'cancel',
              idList: [id],
            }"
            @success="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <div id="BaseInfo" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('基本信息') }}</span>
                  <span class="text-link fz14 ml10" v-if="isDetail" @click="copyOrder">
                    {{ $t('复制单据') }}
                  </span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款单号`)">
                    <a-input disabled v-model="formData.orderSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据类型`)" required>
                    <CommonSelect
                      :list="receipt_note_type"
                      :code.sync="formData.orderType"
                      :placeholder="$t('单据类型')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`业务日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.bizDate"
                      @choose="chooseBizDate"
                      :disabled="isDisabled"
                      :placehold="$t(`业务日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)">
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据状态`)" required>
                    <a-input :value="receiptNoteStatusMapping[formData.status]" disabled />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>

            <div id="AmountInfo" class="modalBox">
              <div class="infoTitle">
                <div>
                  <i class="leftLine"></i>
                  <span>{{ $t('金额信息') }}</span>
                </div>
              </div>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)">
                    <SelectCurrency :disabled="true" :code.sync="formData.functionalCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款货币`)">
                    <SelectCurrency :disabled="isDisabled" :code.sync="formData.receiptCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item required :label="$t(`金额`)">
                    <PriceInput
                      disabled
                      :currency="formData.receiptCurrency"
                      :value="formData.amountWithoutTax"
                      :placeholder="$t(`金额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`税额`)">
                    <PriceInput
                      :disabled="true"
                      :currency="formData.receiptCurrency"
                      :value="formData.taxAmount"
                      :placeholder="$t(`税额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`价税合计`)">
                    <PriceInput
                      :currency="formData.receiptCurrency"
                      :disabled="true"
                      :value.sync="formData.receivableAmount"
                      :placeholder="$t(`价税合计`)"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </a-form-model>
        </section>
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import SelectCurrency from '@component/selectCurrency'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'ReceiptNoteDetail',
  components: { DatePicker, BottomBtns, SelectCurrency },
  data() {
    return {
      id: undefined,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {},
      loading: false,
      draftLoading: false,
      saveLoading: false,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['receipt_note_type']),
    ...mapGetters(['receiptNoteStatusMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      this.isCopy = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
      }
      this.getDetailInfo()
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    async getDetailInfo() {
      if (this.id) {
        this.loading = true
        await http({
          url: api.getReceiptNoteDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.formData = deepClone(result)
          },
        })
        this.loading = false
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    copyOrder() {
      this.formData.tableData = this.tableData
      this.formData.paymentPlanList = this.paymentPlanList
      let data = JSON.stringify(this.formData)
      localStorage.setItem('payableOrderFormdata', data)
      this.$router.push({ name: 'payableDetail' })
    },
    backForm(back = 0) {
      if (back) {
        this.$router.go(-1)
        return
      }
      this.$router.push({ name: 'payableList' })
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      data.status = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      await http({
        url: api.createPaymentRequest,
        data: {
          paymentRequestReqVO: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`layer.Success`),
              content: this.$t(`layer.SavingSucceeded`),
            })
            if (this.isCopy) {
              this.backForm()
            } else {
              this.init()
            }
          } else {
            this.$error({
              title: this.$t(`layer.Error`),
              content: this.$t(`layer.CreateFailed`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
  },
}
</script>
